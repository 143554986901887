import React from "react";
import Header from "src/components/Header";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li className="parent-active">
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="active first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-21">
            <h1>Stress door een hoge werkdruk</h1>
            <div className="img">
              <img
                src="/assets/files/pages/stress.jpeg"
                width="701"
                height="289"
                alt="Hoge werkdruk"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Laat je niet regeren door diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  Iedereen staat in zijn werk wel eens onder druk. Diarree als
                  gevolg van stress is echter een teken dat de druk je
                  lichamelijk en psychisch belast. Afgezien van het feit dat het
                  onaangenaam is om met diarree te moeten werken, kun je er ook
                  moe door raken en dan valt het afhandelen van de dagelijkse
                  taken zwaarder.
                </p>
              </div>
            </div>
            <p>
              <strong>Stress</strong> is op verschillende manieren met succes te
              behandelen en sommige van de adviezen hieronder zullen beter voor
              je&nbsp; werken dan andere. Het is belangrijk dat&nbsp; je een
              eigen manier vindt om gezond met stress om te gaan.
            </p>
            <ul>
              <li>
                <strong>Neem tijd voor jezelf.</strong> Las dus af en toe een
                pauze in! Geen tijd voor een middagpauze? Probeer dan om wat
                later een pauze in te lassen. Je presteert na een pauze vaak
                veel beter.
              </li>
              <li>
                <strong>
                  Zorg voor een{" "}
                  <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                    evenwichtige voeding
                  </a>
                  .
                </strong>
              </li>
              <li>
                <strong>Kom op voor jezelf</strong> en leer nee te zeggen zonder
                anderen voor het hoofd te stoten, of hoe je in een
                probleemsituatie rustig kunt blijven en naar een oplossing kunt
                zoeken, die voor alle betrokkenen aanvaardbaar is.
              </li>
              <li>
                <strong>Leer je tijd doelmatiger in te delen.&nbsp;</strong>Leg
                je prioriteiten voor de dag vast en werk eerst de onaangename
                opdrachten af, zodat je daaraan de hele verdere dag al niet meer
                hoeft te denken.
              </li>
              <li>
                <strong>
                  Aanvaard dat je bepaalde zaken niet kunt veranderen.
                </strong>
                &nbsp;Probeer af en toe dingen vanuit een ander standpunt te
                bekijken, of bespreek bepaalde zaken met je collega’s. Die
                kunnen je misschien op een andere, minder belastende oplossing
                wijzen.
              </li>
              <li>
                <strong>Probeer de zaken anders te zien.</strong>&nbsp;Als iets
                je stoort, probeer het dan vanuit een ander en minder belastend
                perspectief te bekijken. Spreek er met iemand anders over,
                voordat je je limieten overschrijdt.
              </li>
              <li>
                <strong>Lach ook eens!&nbsp;</strong>Probeer het komische van
                een situatie in te zien. Lachen is een wondermiddel om stress af
                te bouwen en het immuunsysteem te versterken, dat door stress
                vaak verzwakt wordt.
              </li>
              <li>
                <strong>
                  Vermijd afhankelijkheid van alcohol, nicotine en cafeïne.
                </strong>
                &nbsp;Op lange termijn versterken deze het probleem alleen nog
                meer. Cafeïne is bijvoorbeeld een stimulerend middel dat stress
                kan veroorzaken.
              </li>
              <li>
                <strong>Zorg voor beweging.</strong>&nbsp;Lichaamsbeweging zet
                serotonine (gelukshormoon) vrij. Dit helpt je stress te
                verminderen.
              </li>
              <li>
                <strong>Leer ontspanningstechnieken.</strong>&nbsp;Goede
                ontspanningstechnieken voor een betere stressbeheersing zijn
                bijvoorbeeld ademhalingsoefeningen, massages en tal van
                aanvullende therapieën.
              </li>
            </ul>
          </div>
        </div>
        <footer className="footer">
        <div className="row">
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
                <ul>
                  <li className="first last">
                    <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                      De werkzame stof: loperamide
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className="first last">
                <a href="/imodium-behandelen-diarree/">
                  IMODIUM® Geneesmiddelen
                </a>
                <ul>
                  <li className="first">
                    <a href="/imodium-behandelen-diarree/imodium-instant/">
                      IMODIUM® Instant Smelttablet
                    </a>
                  </li>
                  <li>
                    <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                      IMODIUM® Capsules
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first last">
                <a href="/feiten-over-diarree/">Feiten over diarree</a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                      Tips voor behandeling
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                      Oorzaken van diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/">
                      Stress en diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                    Hoge werkdruk
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                    Examensituaties
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/buikgriep/">
                      Virale of bacteriële infectie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                      Diarree tijdens de menstruatie
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/chronische-diarree/">
                      Chronische Diarree
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/eenartsraadplegen/">
                      Een arts raadplegen?
                    </a>
                  </li>
                  <li className="">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                      Gezonde eetgewoonten
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul></ul>
            <ul>
              <li className="first last">
                <a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                  <li className="first">
                    <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                      Reizigersdiarree vermijden
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                      Reizigersdiarree behandelen
                    </a>
                  </li>
                  <li>
                    <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="three columns">
            <ul>
              <li className="first">
                <a href="/diarree-bij-kinderen/">Diarree bij kinderen</a>
              </li>
              <li className="here parent-here">
                <a href="/woordenlijst/">Woordenlijst diarree</a>
              </li>
              <li className="last">
                <a href="/veelgestelde-vragen-diarree/">FAQ</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <nav className="nav">
              <ol className="uilist-hor">
                <li className="first">
                  <a href="/sitemap/">Sitemap</a>
                </li>
                <li>
                  <a href="/juridische-kennisgeving/">
                    Juridische Kennisgeving
                  </a>
                </li>
                <li>
                  <a href="/privacybeleid/">Privacybeleid</a>
                </li>
                <li>
                  <a href="/cookiebeleid/">Cookiebeleid</a>
                </li>
                <li className="last">
                  <a href="/contact/">Contact</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="disclaimer">
          <div className="row">
            <div className="twelve columns">
              <p>
                © Johnson &amp; Johnson Consumer B.V. 2010-2017. KOAG nr
                33-1214-1700. Laatst bijgewerkt op: 18 december 2014.{" "}
              </p>
              <p>
                Deze site is gepubliceerd door Johnson &amp; Johnson Consumer
                B.V., die als enige verantwoordelijk is voor de inhoud van de
                site. De site is bedoeld voor een Nederlands publiek.
                <br />
                <br />
                IMODIUM® bevat loperamide voor het verlichten van diarree. Lees
                voor gebruik de bijsluiter. IMODIUM® mag niet gebruikt worden
                bij kinderen jonger dan 8 jaar. Tussen 2 en 8 jaar enkel Imodium
                orale drank gebruiken op medisch voorschrift. Niet gebruiken bij
                aanhoudende buikpijn, bij slijm of bloed in de ontlasting.
              </p>
            </div>
          </div>
        </div>
        <script src="/assets/js/custom.js" />
      </footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
